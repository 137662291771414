import * as types from '../types'

const INITIAL_STATE = {
    loading: true,
}

const isLoadingReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.LOADING:
            return {...state, loading: true};
        case types.LOADED:
            return {...state, loading: false};
        default:
            return state;
    }
}

export default isLoadingReducer