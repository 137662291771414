import React from "react";

import "./InputWithLabel.css";

class InputWithLabel extends React.Component {
    render() {
        return (
            <div className="txt_field">
                <input type={this.props.input_type} required={this.props.input_required} value={this.props.input_value} onChange={this.props.input_change} />
                <span />
                <label>{this.props.input_label}</label>
            </div>
        )
    }
}

export default InputWithLabel;