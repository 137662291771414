import React from "react";

import "./RadiusButton.css";

class RadiusButton extends React.Component {
    render() {
        return (
            <button className={"btn_radius"} type={this.props.button_type}>
                {this.props.children}
            </button>
        )
    }
}

export default RadiusButton;