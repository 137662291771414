import React from "react";
import {connect} from 'react-redux';
import history from "../../history";
import {REGISTER_ACTION} from "../../actions/authActions";

import {isLoggedIn, redirectTo} from "../../utils/AuthUtils";

import Background from "../../components/background/Background";
import Center from "../../components/align/Center";
import FormWithTitle from "../../components/forms/FormWithTitle";
import InputWithLabel from "../../components/input/InputWithLabel";
import RadiusButton from "../../components/buttons/RadiusButton";
import LinkWithLabel from "../../components/links/LinkWithLabel";

class Register extends React.Component {
    state = {firstname: "", lastname: "", username: "", password: "", errMessage: ""}

    constructor(props) {
        super(props);
        if (isLoggedIn(props)) {
            redirectTo("/");
        }
        // this.checkLoggedIn();
    }

    // checkLoggedIn() {
    //     console.log("login")
    //     console.log(this.props.auth)
    //     if(this.props.auth.isLoggedIn){
    //         console.log("already logged in")
    //         history.push({
    //             pathname: '/',
    //         })
    //         history.go(0);
    //     }
    // }

    formSubmit = async (event) => {
        event.preventDefault();
        let errMessage = ""
        await this.props.REGISTER_ACTION({firstname: this.state.firstname, lastname: this.state.lastname, email: this.state.username, password: this.state.password})

        if (this.props.auth.isLoggedIn) {
            window.location.reload();
        }
    }

    render() {
        let errMessage = null;
        if(this.props.error.data){
            errMessage = this.props.error.data.message;
        }
        if(this.state.errMessage){
            errMessage = this.state.errMessage;
        }
        return (
            <Background>
                <Center>
                    <FormWithTitle form_width="400px" form_background="#fafafa" form_title="Register" form_submit={this.formSubmit}>
                        <InputWithLabel input_type={"text"} input_required={true} input_value={this.state.firstname} input_change={(e) => this.setState({firstname: e.target.value})} input_label={"First Name"} />
                        <InputWithLabel input_type={"text"} input_required={true} input_value={this.state.lastname} input_change={(e) => this.setState({lastname: e.target.value})} input_label={"Last Name"} />
                        <InputWithLabel input_type={"text"} input_required={true} input_value={this.state.username} input_change={(e) => this.setState({username: e.target.value})} input_label={"Email address"} />
                        <InputWithLabel input_type={"password"} input_required={true} input_value={this.state.password} input_change={(e) => this.setState({password: e.target.value})} input_label={"Password"} />
                        <div className={`${errMessage ? "" : "d-none"} alert alert-danger`} role="alert">
                            {errMessage}
                        </div>
                        <RadiusButton button_type={"submit"}>Register</RadiusButton>
                        <LinkWithLabel link_label={"Already have an account?"} link_href={"/login"} link_name={"Login"} />
                        <br />
                    </FormWithTitle>
                </Center>
            </Background>
        )

        return (
            <div className="vertical-center">
                <form className="form-register" onSubmit={this.formSubmit}>
                    <h1 className="h3 mb-3 font-weight-normal">Register</h1>
                    <label htmlFor="inputFirstName" className="sr-only">FirstName</label>
                    <br />
                    <input type="text" id="inputFirstName" className="form-control" value={this.state.firstname} placeholder="FirstName" onChange={(e) => this.setState({firstname: e.target.value})} required autoFocus />
                    <br />
                    <label htmlFor="inputLastName" className="sr-only">LastName</label>
                    <br />
                    <input type="text" id="inputLastName" className="form-control" value={this.state.lastname} placeholder="LastName" onChange={(e) => this.setState({lastname: e.target.value})} required autoFocus />
                    <br />
                    <label htmlFor="inputEmail" className="sr-only">Email address</label>
                    <br />
                    <input type="text" id="inputUsername" className="form-control" value={this.state.username} placeholder="Email" onChange={(e) => this.setState({username: e.target.value})} required autoFocus />
                    <br />
                    <label htmlFor="inputPassword" className="sr-only">Password</label>
                    <br />
                    <input type="password" id="inputPassword" className="form-control" value={this.state.password} placeholder="Password" onChange={(e) => this.setState({password: e.target.value})}  required />
                    <br />
                    <div className={`${errMessage ? "" : "d-none"} alert alert-danger`} role="alert">
                        {errMessage}
                    </div>
                    <br />
                    <button className="btn btn-lg btn-primary btn-block" type="submit">Register</button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error }
}

export default connect(mapStateToProps, { REGISTER_ACTION })(Register);