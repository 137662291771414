import React from "react";

import "./FormWithTitle.css";
import Card from "../card/Card";

class FormWithTitle extends React.Component {
    render() {
        return (
            <Card card_width={this.props.form_width} card_background={this.props.form_background}>
            {/*<div className="form" style={{width: this.props.form_width, background: this.props.form_background}}>*/}
                <h1 className={"card-title"}>{this.props.form_title}</h1>
                <form className={"frm"} method={this.props.form_method} onSubmit={this.props.form_submit}>
                    {this.props.children}
                </form>
            {/*</div>*/}
            </Card>
        )
    }
}

export default FormWithTitle;