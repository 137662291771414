import React from "react";

import "./Card.css";

class Card extends React.Component {
    render() {
        return (
            <div className="card" style={{width: this.props.card_width, background: this.props.card_background}}>
                {this.props.children}
            </div>
        )
    }
}

export default Card;