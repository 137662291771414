import React from "react";

import "./Background.css";

class Background extends React.Component {
    render() {
        return (
            <div className="dark">
                {this.props.children}
            </div>
        )
    }
}

export default Background;