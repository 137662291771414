import * as types from '../types'
import axios from 'axios';
import cookies from 'js-cookie'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000
});

const LOGOUT_ACTION = () => async(dispatch) => {
    if (cookies.get('token')) {
        const params = new FormData();
        params.append('token', cookies.get('hash'));
        const response = await api.post('/auth/logout', params, {
            validateStatus: function (status) {
                return status < 600; // Reject only if the status code is greater than or equal to 500
            }
        });

        if (response.status === 200) {
            console.log('Logout success');
        }
    }

    console.log('logout action')
    cookies.remove("hash");
    cookies.remove("data");
    dispatch(_LOGOUT_ACTION());
}

const LOGIN_ACTION = (data) => async (dispatch) => {
    const params = new FormData();
    params.append('email', data.email);
    params.append('password', data.password);

    try {
        const response = await api.post('/auth/login', params, {
                validateStatus: function (status) {
                    return status < 600; // Reject only if the status code is greater than or equal to 500
                }
            }
        );
        console.log(response.data);

        if (response.status >= 400) {
            console.log(response)
            dispatch(LOGIN_ERROR_ACTION(response.data))
        } else {
            if(response.data.session.token) {
                cookies.set("hash", response.data.session.token);
                cookies.set("data", btoa(JSON.stringify(response.data)));
                dispatch(LOGIN_ERROR_ACTION(null));
                dispatch(_LOGIN_ACTION(response.data));
            }else {
                dispatch(LOGIN_ERROR_ACTION(response.data));
            }
        }

    } catch (error)
    {
        dispatch(LOGIN_ERROR_ACTION({code: 400, message: "Something went wrong, please try again."}))
    }
}

const REGISTER_ACTION = (data) => async (dispatch) => {
    const params = new FormData();
    params.append('firstname', data.firstname);
    params.append('lastname', data.lastname);
    params.append('email', data.email);
    params.append('password', data.password);

    const response = await api.post('/auth/register', params, {
            validateStatus: function (status) {
                return status < 600; // Reject only if the status code is greater than or equal to 500
            }
        }
    );

    console.log(response.data);

    if (response.status === 422) {
        dispatch(LOGIN_ERROR_ACTION(response.data))
    }
}

const VERIFY_ACTION = (data) => async (dispatch) => {
    const params = new FormData();
    params.append('email', data.email);
    params.append('token', data.token);

    const response = await api.post('/auth/verify-email', params, {
            validateStatus: function (status) {
                return status < 600; // Reject only if the status code is greater than or equal to 500
            }
        }
    );

    console.log(response.data);

    if (response.status === 200) {
        dispatch(_VERIFY_ACTION());
    } else {
        dispatch(VERIFY_ERROR_ACTION(response.data));
    }
}

const USER_CHECK_ACTION = () => async (dispatch) => {
    let cookieData = atob(cookies.get("data"));
    let data = JSON.parse(cookieData);
    console.log(data)
    console.log(cookies.get("hash"))
    if (data && cookies.get("hash")) {
        dispatch(_LOGIN_ACTION(data));
        return true;
    }
    return false;
}

const LOGIN_ERROR_ACTION = data => {
    return {
        type: types.LOGIN_ERROR,
        payload: data
    }
}

const VERIFY_ERROR_ACTION = data => {
    return {
        type: types.VERIFY_ERROR,
        payload: data
    }
}

const _LOGIN_ACTION = (data) => {
    return {
        type: types.LOGIN,
        payload: data
    }
}

const _LOGOUT_ACTION = () => {
    return {
        type: types.LOGOUT
    }
}

const _VERIFY_ACTION = () => {
    return {
        type: types.VERIFY
    }
}

export {LOGOUT_ACTION, LOGIN_ACTION, REGISTER_ACTION, VERIFY_ACTION, USER_CHECK_ACTION}