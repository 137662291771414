import history from "../history";

const isLoggedIn = (props) => {
    console.log(props.auth);
    return props.auth.isLoggedIn;
}

/**
 * @deprecated use isLoggedIn() + redirect instead
 */
const checkLoggedIn = (props) => {
    console.log("login")
    console.log(props.auth)
    if (props.auth.isLoggedIn) {
        console.log("already logged in")
        history.push({
            pathname: '/',
        })
        history.go(0);
    }
}

/**
 * @deprecated use isLoggedIn() + redirect instead
 */
const checkLoggedOut = (props) => {
    console.log("logout");
    console.log(props.auth)
    history.push({
        pathname: '/',
    })
    history.go(0);
}

const redirectTo = (path) => {
    console.log("redirecting to " + path);
    history.push({
        pathname: `${path}`,
    })
    history.go(0);
}

export {redirectTo, isLoggedIn, checkLoggedIn, checkLoggedOut};