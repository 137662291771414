import React from "react";

import "./LinkWithLabel.css";

class LinkWithLabel extends React.Component {
    render() {
        return (
        <div className="link">
            <label>{this.props.link_label}</label> <a href={this.props.link_href}>{this.props.link_name}</a>
        </div>
        );
    }
}

export default LinkWithLabel;