import * as types from '../types'

const INITIAL_STATE = {
    data: null,
}

const errorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case types.ERROR:
            return {...state, data: action.payload};
        case types.LOGIN_ERROR:
            return {...state, data: action.payload};
        default:
            return state;
    }
}

export default errorReducer;