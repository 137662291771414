import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {connect} from 'react-redux'
import history from "../history";
import cookies from "js-cookie";

import Default from "./index";
import Login from "./authentication/Login";
import Logout from "./authentication/Logout";
import Register from "./authentication/Register";
import Verify from "./authentication/Verify";

import {USER_CHECK_ACTION, LOGOUT_ACTION} from "../actions/authActions";

class App extends React.Component {
    constructor(props) {
        super(props);
        console.log("default")
        if (cookies.get('hash')) {
            if(!this.props.USER_CHECK_ACTION()) {

                console.log("default push login")
                history.push("/login")
            }

            console.log("default dont push")

        }else{
            console.log("default else")
            this.props.auth.isLoggedIn === false ? this.props.LOGOUT_ACTION() : history.push("/")
        }
    }

    render() {
        return (
            <React.Fragment>
                <Router history={history}>
                    <Routes>
                        <Route path="/" exact element={<Default />}  />
                        <Route path="/login" element={<Login />}  />
                        <Route path="/logout" element={<Logout />}  />
                        <Route path="/register" element={<Register />}  />
                        <Route path="/verify" element={<Verify />}  />
                        <Route path="*" exact element={<Default />}  />
                    </Routes>
                </Router>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return { loading: state.loading, auth: state.auth }
}

export default connect(mapStateToProps, { USER_CHECK_ACTION, LOGOUT_ACTION })(App);