import React from "react";
import {connect} from 'react-redux';
import history from "../../history";
import {LOGOUT_ACTION} from "../../actions/authActions";

import {isLoggedIn, redirectTo} from "../../utils/AuthUtils";

class Logout extends React.Component {
    componentDidMount() {
        this.logout().then(r => {
            if (!isLoggedIn(this.props)) {
                redirectTo("/");
            }
            //checkLoggedOut()
            // this.checkLoggedOut();
        });
    }

    logout = async () => {
        await this.props.LOGOUT_ACTION();
    };


    // checkLoggedOut() {
    //     console.log("logout");
    //     console.log(this.props.auth)
    //     history.push({
    //         pathname: '/',
    //     })
    //     history.go(0);
    // }

    render() {
        return (
            <div>
                <h1>Logout</h1>
                <p>You have been logged out</p>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error }
}

export default connect(mapStateToProps, { LOGOUT_ACTION })(Logout);