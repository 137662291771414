import React from "react";
import {connect} from 'react-redux';
import history from "../../history";

import Center from "../../components/align/Center";
import LinkWithLabel from "../../components/links/LinkWithLabel";
import Background from "../../components/background/Background";
import Card from "../../components/card/Card";

import {isLoggedIn, redirectTo} from "../../utils/AuthUtils";

import {VERIFY_ACTION} from "../../actions/authActions";

class Verify extends React.Component {
    constructor(props) {
        super(props);
        if (isLoggedIn(props)) {
            redirectTo("/");
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        let email = params.get("email");
        let token = params.get("token");

        if (!email || !token) {
            redirectTo("/");
        }

        let errMessage = ""
        await this.props.VERIFY_ACTION({email: params.get("email"), token: params.get("token")})
    }

    render() {
        return (
            <Background>
                <Center>
                    <Card card_width="600px" card_background="#fafafa">
                        <h1 className={"card-title"}>Verify</h1>
                        <div className={"card-body"}>
                            <p className={"card-text-center"}>Your account is now verified</p>
                            <LinkWithLabel link_label={"U can now login!"} link_href={"/login"} link_name={"Login"} />
                            <br/>
                        </div>
                    </Card>
                </Center>
            </Background>
        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error }
}

export default connect(mapStateToProps, { VERIFY_ACTION })(Verify);