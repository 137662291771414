import * as types from '../types'

const INITIAL_STATE = {
    isLoggedIn: false,
    userData: null
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type){
        case types.LOGIN:
            console.log("login")
            return {...state, isLoggedIn: true, userData: {data: action.payload}};
        case types.LOGOUT:
            console.log("logout")
            return {...state, isLoggedIn: false, userData: {}};
        case types.REGISTER:
            console.log("register")
            return {...state, isLoggedIn: false, userData: {}, data: action.payload};
        default:
            return state;
    }
}

export default authReducer;