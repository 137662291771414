import React, {useEffect} from "react";

import {isLoggedIn, redirectTo} from "../../utils/AuthUtils";

import './Dashboard.css';

class Nav extends React.Component {
    componentDidMount() {
        let menu = document.querySelector(".menu");
        let sidebar = document.querySelector(".sidebar");

        menu.addEventListener("click", function() {
            sidebar.classList.toggle("active");
        })
    }

    checkProfile() {
        if(!this.props.avatar || !this.props.first_name || !this.props.last_name){
            redirectTo("/login")
        }
    }

    render() {
        this.checkProfile()
        return (
            <nav className="sidebar active">
                <div className="logo">
                    <div className="logo-content">
                        <i className="material-icons fa-2xl">add_photo_alternate</i>
                        <div className="logo-name">VW-Net</div>
                    </div>
                    <i className="menu material-icons">menu</i>
                </div>
                <ul className="p-0">
                    <li className="m-0">
                        <a href="#">
                            <i className="material-icons">dashboard</i>
                            <span className="link-name">
                        Dashboard
                    </span>
                        </a>
                    </li>
                    <li  className="m-0">
                        <a href="#">
                            <i className="material-icons">chat_bubble_outline</i>
                            <span className="link-name">
	                    Messages
                    </span>
                        </a>
                    </li>
                    <li className="m-0">
                        <a href="#">
                            <i className="material-icons">people</i>
                            <span className="link-name">
                        Users
                    </span>
                        </a>
                    </li>
                    <li className="m-0">
                        <a href="#">
                            <i className="nav-icon material-icons">folder_open</i>
                            <span className="link-name">
                        Projects
                    </span>
                        </a>
                    </li>
                    <li className="m-0">
                        <a href="#">
                            <i className="nav-icon material-icons">widgets</i>
                            <span className="link-name">
                        Widgets
                    </span>
                        </a>
                    </li>
                    <li className="m-0">
                        <a href="#">
                            <i className="nav-icon material-icons">settings</i>
                            <span className="link-name">
                        Settings
                    </span>
                        </a>
                    </li>
                </ul>

                <div className="profile-content">
                    <div className="profile">
                        <div className="row align-items-center justify-content-end profile-details m-1">
                            <img className="col-3 p-0 profile-image"
                                 src={this.props.avatar}
                                 alt=""
                                />
                            <div className="profile-info col-6">
                                <div className="name">
                                    {this.props.first_name} {this.props.last_name}
                                </div>
                                <div className="job-position">
                                    Beheerder
                                </div>
                            </div>
                            <i onClick={() => document.location.href = '/logout'} className="col-2 logout material-icons">logout</i>
                            <div className="col-1"></div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Nav;