import React from "react";
import {connect} from 'react-redux';
import history from "../history";
import {isLoggedIn, redirectTo} from "../utils/AuthUtils";

import Nav from "../components/dashboard/Nav";

class Index extends React.Component {
    state = {searchValue: ''}

    constructor(props) {
        super(props);
        if (!isLoggedIn(props)) {
            redirectTo("/login");
        }
    }

    componentDidMount() {

    }

    // checkLoggedIn() {
    //     console.log("login")
    //     console.log(this.props.auth)
    //     if(!this.props.auth.isLoggedIn){
    //         console.log("not logged in")
    //         history.push({
    //             pathname: '/login',
    //         })
    //         history.go(0);
    //     }
    // }


    renderWelcome() {
        return (
            <Nav avatar={this.props.auth.userData.data.user.avatar} first_name={this.props.auth.userData.data.user.first_name} last_name={this.props.auth.userData.data.user.last_name} />
        )
    }
    render() {
        return(
            <React.Fragment>
                {this.renderWelcome()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {auth: state.auth, error: state.error}
}

export default  connect(mapStateToProps, {  })(Index);