import React from "react";
import {connect} from 'react-redux';
import history from "../../history";
import {LOGIN_ACTION} from "../../actions/authActions";

import {isLoggedIn, redirectTo} from "../../utils/AuthUtils";

import InputWithLabel from "../../components/input/InputWithLabel";
import Center from "../../components/align/Center";
import FormWithTitle from "../../components/forms/FormWithTitle";
import Background from "../../components/background/Background";
import RadiusButton from "../../components/buttons/RadiusButton";
import LinkWithLabel from "../../components/links/LinkWithLabel";

class Login extends React.Component {
    state = {username: "", password: "", errMessage: ""}

    constructor(props) {
        super(props);
        if (isLoggedIn(props)) {
            redirectTo("/");
        }
        // this.checkLoggedIn();
    }

    // checkLoggedIn() {
    //     console.log("login")
    //     console.log(this.props.auth)
    //     if(this.props.auth.isLoggedIn){
    //         console.log("already logged in")
    //         history.push({
    //             pathname: '/',
    //         })
    //         history.go(0);
    //     }
    // }

    formSubmit = async (event) => {
        event.preventDefault();
        let errMessage = ""
        await this.props.LOGIN_ACTION({email: this.state.username, password: this.state.password})

        if (isLoggedIn(this.props)) {
            window.location.reload();
        }
    }

    render() {
        let errMessage = null;
        if(this.props.error.data){
            errMessage = this.props.error.data.message;
        }
        if(this.state.errMessage){
            errMessage = this.state.errMessage;
        }

        return (
            <Background>
                <Center>
                    <FormWithTitle form_width="400px" form_background="#fafafa" form_title="Login" form_submit={this.formSubmit}>
                        <InputWithLabel input_type={"text"} input_required={true} input_value={this.state.username} input_change={(e) => this.setState({username: e.target.value})} input_label={"Email address"} />
                        <InputWithLabel input_type={"password"} input_required={true} input_value={this.state.password} input_change={(e) => this.setState({password: e.target.value})} input_label={"Password"} />
                        <div className={`${errMessage ? "" : "d-none"} alert alert-danger`} role="alert">
                            {errMessage}
                        </div>
                        <RadiusButton button_type={"submit"}>Login</RadiusButton>
                        <LinkWithLabel link_label={"Don't have an account?"} link_href={"/register"} link_name={"Register"} />
                        <br />
                    </FormWithTitle>
                </Center>
            </Background>
        )
    }
}

const mapStateToProps = (state) => {
    return { auth: state.auth, error: state.error }
}

export default connect(mapStateToProps, { LOGIN_ACTION })(Login);