import { combineReducers } from 'redux';

import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    error: errorReducer
})